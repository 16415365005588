<template>
	<el-container class="consign-order">
		<el-header class="page-head" height="49">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ name: 'batchConsign' }">{{$t('seller.breadcrumb.shipping')}}</el-breadcrumb-item>
				<el-breadcrumb-item>{{$t('seller.breadcrumb.startShipping')}}</el-breadcrumb-item>
			</el-breadcrumb>
		</el-header>
		<el-scrollbar class="uu-box">
			<div class="page-container">
				<div class="card-head">
					<h1 class="title">{{$t('seller.order.consignPane1')}}</h1>
				</div>
				<div class="card-table" v-for="(order,index) in orderList" :key="index">
					<table cellpadding="0" cellspacing="0" width="100%">
						<tr class="bg-grey">
							<td colspan="3">
								<div class="table-cell table-head">
									<div class="number">{{$t('seller.order.number')}}{{order.order_sn}}</div>
									<div class="datetimes">
										<div>{{$t('seller.order.payment')}}{{getPayHour(order.pay_time)}}{{$t('seller.unit.hour')}}</div>
										<div>{{$t('seller.order.payTime')+':'}}{{order.pay_time}}</div>
									</div>
								</div>
							</td>
						</tr>
						<tr v-for="(goods,index2) in order.order_goods" :key="index2">
							<td width="555px">
								<div class="table-cell table-goods">
									<img class="pic" :src="goods.goods_image" >
									<div class="goods-wrap">
										<div class="goods-main">
											<div class="name">{{goods.goods_name}}</div>
											<div class="text">
												<!-- <el-tag size="mini"></el-tag> -->
											</div>
										</div>
									</div>
								</div>
							</td>
							<td width="166px"><div class="table-cell">￥{{goods.price}}*{{goods.quantity}}</div></td>
							<td width="277px">
								<div class="table-cell">
									<div>
										<p><span>{{$t('seller.order.shippingTime')}}</span></p>
										<p>{{'48'+$t('seller.unit.hour')+$t('seller.order.shipping')}}</p>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<div class="table-cell table-foot">
									<div class="foot-item mb6">
										<div class="item-label">{{$t('seller.order.buyerConsign')}}</div>
										<div class="item-block">
											<div class="address">
												<span v-if="visibleAddress.includes(order.order_id)">{{order.receive_address.region_name}}{{order.receive_address.address}}，{{order.receive_address.zipcode?order.receive_address.zipcode:'000000'}}，{{order.receive_address.consignee}}，{{order.receive_address.phone_mob}}</span>
												<span v-else>{{order.receive_address.region_name}}{{onAddressText(order.receive_address.address)}}，{{onAddressName(order.receive_address.consignee)}}，{{onAddressMobild(order.receive_address.phone_mob)}}</span>
												<i class="uu-icon-view" :class="visibleAddress.includes(order.order_id)?'active':''" @click="handleVisibleAddress(order.order_id)"></i>
												<i class="el-icon-edit" @click="handleEditAddressShow(order)"></i>
											</div>
										</div>
									</div>
									<div class="foot-item" v-if="$route.params.type=='logis'">
										<div class="item-label" style="padding-top: 8px;">{{$t('seller.order.addLogisticsNo')}}</div>
										<div class="item-block">
											<div class="item-logis">
												<ul class="item-action">
													<li class="w260"><el-input v-model="logisForm[order.order_id].logistic_code" size="medium" :placeholder="$t('seller.placeholder.logisticsNo')"></el-input></li>
													<li class="w260"><el-input v-model="logisForm[order.order_id].remark" size="medium" :placeholder="$t('seller.placeholder.logisticsRemark')"></el-input></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					</table>
				</div>
				
				<el-dialog
					:title="$t('seller.dialogTitle.modifyAddress')"
					width="876px"
					class="dialog-address"
					:visible.sync="dialogVisibleAddress"
					:before-close="handleEditAddressHide">
					<el-scrollbar>
						<div class="dialog-address-main">
							<div class="item">
								<div class="item-label">{{$t('seller.order.dialogAddress1')}}</div>
								<div class="item-block">{{orderInfo.order_sn}}</div>
							</div>
							<div class="item">
								<div class="item-label">{{$t('seller.order.dialogAddress2')}}</div>
								<div class="item-block">
									<div class="address-line">
										<p><span class="line-label">{{$t('seller.order.consignee')}}</span><span>{{orderInfo.receive_address?orderInfo.receive_address.consignee:''}}</span></p>
										<p><span class="line-label">{{$t('seller.order.receivingTel')}}</span><span v-if="orderInfo.receive_address">{{orderInfo.receive_address.phone_mob?orderInfo.receive_address.phone_mob:orderInfo.receive_address.phone_tel}}</span></p>
										<p><span class="line-label">{{$t('seller.order.receivingAddress')}}</span><span>{{orderInfo.receive_address?orderInfo.receive_address.region_name:''}}{{orderInfo.receive_address?orderInfo.receive_address.address:''}}</span></p>
									</div>
								</div>
							</div>
							<div class="item">
								<div class="item-label">{{$t('seller.order.dialogAddress3')}}</div>
								<div class="item-block">
								</div>
							</div>
							<div class="item">
								<el-form label-position="top" size="small" style="width: 100%;">
									<el-row :gutter="20">
										<el-col :span="12">
											<el-form-item>
												<div class="item-title" slot="label">
													<span>{{$t('seller.formItem.consignee')}}</span>
													<a href="javascript:void(0);" @click="handleEditAddressAction('consignee')">{{addressDisabled.consignee?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
												</div>
												<el-input v-model="addressForm.consignee" :disabled="addressDisabled.consignee"></el-input>
											</el-form-item>
										</el-col>
										<el-col :span="12">
											<el-form-item>
												<div class="item-title" slot="label">
													<span>{{$t('seller.formItem.receivingTel')}}</span>
													<a href="javascript:void(0);" @click="handleEditAddressAction('phone_mob')">{{addressDisabled.phone_mob?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
												</div>
												<el-input v-model="addressForm.phone_mob" :maxlength="16" :disabled="addressDisabled.phone_mob"></el-input>
											</el-form-item>
										</el-col>
									</el-row>
									<el-form-item>
										<div class="item-title" slot="label">
											<span>{{$t('seller.formItem.landline')}}</span>
											<a href="javascript:void(0);" @click="handleEditAddressAction('phone_tel')">{{addressDisabled.phone_tel?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
										</div>
										<el-row>
											<el-col :span="12"><el-input v-model="addressForm.phone_tel" :maxlength="16" :disabled="addressDisabled.phone_tel" :placeholder="$t('seller.placeholder.landline')"></el-input></el-col>
										</el-row>
									</el-form-item>
									<div class="item">
										<div class="item-title">
											<span>{{$t('seller.formItem.receivingAddress')}}</span>
											<a href="javascript:void(0);" @click="handleEditAddressAction('region')">{{addressDisabled.region?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
										</div>
									</div>
									<div style="padding-left: 20px;">
										<el-row :gutter="20">
											<el-col :span="12" v-for="(item,index) in regionList" :key="index">
												<el-form-item :label="onRegionName(index)">
													<el-select v-model="regionIds[index]" @change="handleAddressChange($event,index)" :disabled="addressDisabled.region" clearable>
														<el-option v-for="address in item" :key="address.region_id" :label="address.region_name"
															:value="address.region_id"></el-option>
													</el-select>
												</el-form-item>
											</el-col>
										</el-row>
										<el-form-item :label="$t('seller.formItem.address')">
											<el-input type="textarea" :rows="4" v-model="addressForm.address" :disabled="addressDisabled.region"></el-input>
										</el-form-item>
									</div>
								</el-form>
							</div>
						</div>
					</el-scrollbar>
					<div slot="footer">
						<el-button @click="handleEditAddressHide">{{$t('seller.actions.cancel')}}</el-button>
						<el-button type="primary" @click="handleEditAddressConfirm" :disabled="confirmDissbled">{{$t('seller.actions.confirm')}}</el-button>
					</div>
				</el-dialog>
				<template v-if="$route.params.type!=='nologis'">
					<div class="card-head">
						<h1 class="title">{{$t('seller.order.consignPane2')}}</h1>
						<div class="notice"><i class="el-icon-warning"></i>{{$t('seller.order.consignTips7')}}</div>
					</div>
					<div class="card-info">
						<div class="item">
							<div class="item-label">{{$t('seller.order.myShipping')}}</div>
							<div class="item-block">
								<span v-if="develifyList.shipping_address">{{develifyList.shipping_address.region_name}}{{develifyList.shipping_address.address}},
								{{develifyList.shipping_address.zipcode?develifyList.shipping_address.zipcode:'000000'}},
								{{develifyList.shipping_address.shipaddr_name}},
								{{develifyList.shipping_address.phone_mob?develifyList.shipping_address.phone_mob:develifyList.shipping_address.phone_tel}}</span>
								<i class="el-icon-edit" @click="handleOpenAddressBase('shipping_address')"></i>
							</div>
						</div>
						<div class="item">
							<div class="item-label">{{$t('seller.order.myReturnInfo')}}</div>
							<div class="item-block">
								<span v-if="develifyList.receiving_address">{{develifyList.receiving_address.region_name}}{{develifyList.receiving_address.address}},
								{{develifyList.receiving_address.zipcode?develifyList.receiving_address.zipcode:'000000'}},
								{{develifyList.receiving_address.shipaddr_name}},
								{{develifyList.receiving_address.phone_mob?develifyList.receiving_address.phone_mob:develifyList.receiving_address.phone_tel}}</span>
								<i class="el-icon-edit" @click="handleOpenAddressBase('receiving_address')"></i>
							</div>
						</div>
					</div>
					<el-dialog
						class="dialog-addressbase"
						width="500px"
						:visible.sync="dialogVisibleAddressBase"
						:before-close="handleCloseAddressBase">
						<div slot="title">
							<span>{{$t('seller.dialogTitle.address')}}</span>
							<a href="/home/order/batch-consign/logis-tools" target="_blank">{{$t('seller.actions.createAddress')}}</a>
							<a href="javascript:void(0);" @click="getAddressBaseList">{{$t('seller.actions.refresh')}}</a>
						</div>
						<el-table :data="addressBaseList" max-height="500">
							<el-table-column :label="$t('seller.tableHead.address')">
								<template slot-scope="scope">
									{{scope.row.region_name}}{{scope.row.address}},
									{{scope.row.zipcode?scope.row.zipcode:'000000'}},
									{{scope.row.shipaddr_name}},
									{{scope.row.phone_tel}},
									{{scope.row.phone_mob}}
								</template>
							</el-table-column>
							<el-table-column :label="$t('seller.tableHead.handle')" width="100px">
								<template slot-scope="scope">
									<el-button
										:disabled="develifyList[addressBaseType].shipaddr_id==scope.row.shipaddr_id?true:false"
										@click="handleChangeAddress(scope.row)"
										size="small"
										round>
										{{develifyList[addressBaseType].shipaddr_id==scope.row.shipaddr_id?$t('seller.actions.selected'):$t('seller.actions.select')}}
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-dialog>
				</template>
				<template v-if="$route.params.type=='list'">
					<div class="card-head">
						<h1 class="title">{{$t('seller.order.consignPane3')}}</h1>
						<div class="action">
							<el-radio-group v-model="logisType" size="medium">
								<el-radio-button v-for="(item,index) in logisticsOption" :key="index" :label="item.value">{{item.label}}</el-radio-button>
							</el-radio-group>
							<div class="w300">
								<el-input v-model="logisRemark" size="medium" :placeholder="$t('seller.placeholder.shippingRemark')" maxlength="125" show-word-limit></el-input>
							</div>
						</div>
					</div>
					<div class="card-hr"></div>
					<div class="card-pattern" v-if="parseInt(logisType)===2">
						<div class="item">
							<div class="item-block">
								<el-input v-model="logisCode" :placeholder="$t('seller.placeholder.orderSn')">
									<template slot="prepend">{{$t('seller.order.addLogisticsNo')}}</template>
								</el-input>
							</div>
							<!-- <div class="item-label">
								<el-tooltip effect="dark" :content="$t('seller.order.consignTips8')" placement="top">
									<div>
										<span>多运单发货</span>
										<i class="el-icon-question"></i>
									</div>
								</el-tooltip>
							</div> -->
						</div>
					</div>
					
					<div class="card-pattern" v-else-if="logisType===1">
						<div class="uu-message">
							<div class="message-notice">
								<i class="el-icon-warning"></i>
								<div class="message-notice-main">
									<p>{{$t('seller.order.consignTips9')}}</p>
								</div>
							</div>
						</div>
						<div class="tip">{{$t('seller.order.consignTips10')}}</div>
					</div>
				</template>
				
			</div>
			<div class="page-foot">
				<el-button type="primary" @click="handleSubmit" round>{{$t('seller.actions.confirmShip')}}</el-button>
			</div>
		</el-scrollbar>
	</el-container>
</template>

<script>
	import { fetchRegion } from '@/api/user';
	import { fetchAddressList } from '@/api/seller/logistics';
	import { deliverGoodsInfo, updateAddress, onDeliverGoods } from '@/api/seller/order';

	const defaultForm = {
		address: '',
		consignee: '',
		phone_mob: '',
		phone_tel: '',
		region_id: 0,
		region_id0: 0,
		region_id1: 0,
		region_id2: 0,
		region_id3: 0,
		region_name: '',
		zipcode: '',
	}
	const defaultOrder = {
		receive_address: {}
	}

	export default {
		data() {
			return {
				radio1: '1',
				value2: '',
				
				visibleAddress: [],
				
				dialogVisibleAddress: false,
				orderList: [],
				develifyList: {
					receiving_address: {},
					shipping_address: {},
				},
				
				orderInfo: Object.assign({}, defaultOrder),
				addressForm: Object.assign({}, defaultForm),
				addressDisabled: {
					consignee: true,
					phone_mob: true,
					phone_tel: true,
					region: true,
				},
				confirmDissbled: true,
				regionList: [],
				regionIds: [],
				// 地址库
				dialogVisibleAddressBase: false,
				addressBaseList: [],
				addressBaseType: '',
				
				logisForm: {},
				logisticsOption: this.$t('seller.order.logisticsOption'),
				logisType: 2,
				logisRemark: '',
				logisCode: '',
			}
		},
		created() {
			this.getOrderInfo();
			this.getRegionList();
			this.getAddressBaseList();
		},
		methods: {
			getOrderInfo(){
				let loading = this.$loading();
				deliverGoodsInfo({order_id: this.$route.params.id}).then(response => {
					loading.close();
					this.orderList = response.data.list;
					this.develifyList = response.data.store_develify_info;
					this.setOrderInfo(response.data.list);
				}).catch(()=>{
					loading.close();
					this.$router.push({name: 'batchConsign'});
				})
			},
			setOrderInfo(order){
				let logis_form = {};
				order.forEach(item=>{
					logis_form[item.order_id] = { logistic_code: '',remark:'' }
				})
				this.logisForm = logis_form;
			},
			getRegionList(pid = 45056, index = -1) {
				fetchRegion({parent_id: pid}).then(response => {
					this.regionList.splice(index);
					if (response.data.list && response.data.list.length > 0) {
						this.regionList.push(response.data.list);
					}
				})
			},
			getPayHour(datetime){
				if(datetime){
					var date = new Date();
					var curTime = new Date(datetime);
					var hour = (date.getTime()-curTime.getTime())/3600000;
					return parseInt(hour);
				}else{
					return '-';
				}
			},
			onAddressText(val){
				return '*****************';
			},
			onAddressName(val){
				if(val){
					return val.substr(0,1)+"**";
				}else{
					return '';
				}
			},
			onAddressMobild(tel){
				return "***********";
			},
			handleVisibleAddress(index){
				if(this.visibleAddress.includes(index)){
					this.visibleAddress.splice(this.visibleAddress.indexOf(index),1);
				}else {
					this.visibleAddress.push(index);
				}
			},
			handleEditAddressShow(data){
				let that = this;
				let promiseArr = [];
				let regionIds = [];
				that.orderInfo = JSON.parse(JSON.stringify(data));
				that.addressForm = JSON.parse(JSON.stringify(data.receive_address));
				that.regionList.splice(1);
				for(let i=0;i<4;i++){
					if(that.addressForm['region_id' + i] > 0){
						regionIds.push(that.addressForm['region_id' + i]);
						promiseArr.push(that.initRegionList(that.addressForm['region_id' + i]));
					}
				}
				Promise.all(promiseArr).then(object=>{
					object.forEach(item=>{
						if(item.length>0){
							that.regionList.push(item);
						}
					})
				})
				that.regionIds = regionIds;
				that.dialogVisibleAddress = true;
			},
			initRegionList(pid=45056){
				return new Promise((resolve,reject)=>{
					fetchRegion({parent_id: pid}).then(response => {
						resolve(response.data.list);
					}).catch(error => {
						reject(error);
					})
				})
			},
			// 修改收货信息
			handleEditAddressAction(type){
				let that = this;
				if(!this.addressDisabled[type]){
					let data = JSON.parse(JSON.stringify(this.orderInfo.receive_address));
					let promiseArr = [];
					let regionIds = [];
					switch(type){
						case 'consignee':
							this.addressForm.consignee = data.consignee;
						break;
						case 'phone_mob':
							this.addressForm.phone_mob = data.phone_mob;
							break;
						case 'phone_tel':
							this.addressForm.phone_tel = data.phone_tel;
							break;
						case 'region':
							that.regionList.splice(1);
							for(let i=0;i<4;i++){
								if(that.addressForm['region_id' + i] > 0){
									regionIds.push(that.addressForm['region_id' + i]);
									promiseArr.push(that.initRegionList(that.addressForm['region_id' + i]));
								}
							}
							Promise.all(promiseArr).then(object=>{
								object.forEach(item=>{
									if(item.length>0){
										that.regionList.push(item);
									}
								})
							})
							that.regionIds = regionIds;
							this.addressForm.address = data.address;
							break;
					}
				}
				this.addressDisabled[type] = this.addressDisabled[type]?false:true;
			},
			handleEditAddressHide(){
				this.orderInfo = Object.assign({}, defaultOrder)
				this.addressForm = Object.assign({}, defaultForm)
				this.regionIds = []
				this.dialogVisibleAddress = false;
			},
			onRegionName(index){
				const regionJson =  this.$t('seller.order.regionJson')
				return regionJson[index];
			},
			handleAddressChange(val, index){
				if(val){
					this.regionIds.splice(index + 1);
					this.getRegionList(val, index + 1);
				}else {
					this.regionIds.splice(index);
					this.regionList.splice(index+1);
				}
			},
			handleEditAddressConfirm(){
				let that = this;
				if(!that.addressForm.consignee){
					that.$message.error(that.$t('seller.errorMsg.consignee'));
					return false;
				}
				if(!that.addressForm.phone_mob){
					that.$message.error(that.$t('seller.errorMsg.recipientMobile'));
					return false;
				}
				if(that.regionIds){
					that.addressForm.region_id = that.regionIds[that.regionIds.length-1];
					that.addressForm.region_id0 = that.regionIds[0] > 0 ? that.regionIds[0] : 0;
					that.addressForm.region_id1 = that.regionIds[1] > 0 ? that.regionIds[1] : 0;
					that.addressForm.region_id2 = that.regionIds[2] > 0 ? that.regionIds[2] : 0;
					that.addressForm.region_id3 = that.regionIds[3] > 0 ? that.regionIds[3] : 0;
					let regionName = '';
					for(let i = 0;i<that.regionIds.length;i++){
						that.regionList[i].forEach(item=>{
							if(item.region_id == that.regionIds[i]){
								regionName+=item.region_name;
							}
						})
					}
					that.addressForm.region_name = regionName;
				}else {
					that.$message.error(that.$t('seller.errorMsg.region'));
					return false;
				}
				if(!that.addressForm.address){
					that.$message.error(that.$t('seller.errorMsg.address'));
					return false;
				}
				let loading = that.$loading();
				let param = {
					...that.addressForm,
					order_id: that.orderInfo.order_id
				}
				updateAddress(param).then(() => {
					loading.close()
					that.$message.success(that.$t('seller.successMsg.modify'))
					that.getOrderInfo();
					that.handleEditAddressHide();
				}).catch(() => {
					loading.close()
				})
			},
			getAddressBaseList(){
				let loading= this.$loading();
				let param = {
					page_index: 0,
					page_size: 50
				}
				fetchAddressList(param).then(response => {
					loading.close();
					this.addressBaseList = response.data.list;
				}).catch(() => {
					loading.close();
				})
			},
			handleOpenAddressBase(val){
				this.addressBaseType = val;
				this.dialogVisibleAddressBase = true;
			},
			handleCloseAddressBase(){
				this.dialogVisibleAddressBase = false;
			},
			handleChangeAddress(data){
				this.develifyList[this.addressBaseType] = data;
				this.dialogVisibleAddressBase = false;
			},
			handleAddLOgisGoods(id,index){
				this.logisForm[id][index].goods.push({rec_id:'',quantity:''});
			},
			handleDelLOgisGoods(id,index,index2){
				this.logisForm[id][index].goods.splice(index2,1);
			},
			onDisabledOption(goods,id){
				let flag = false;
				goods.forEach(item=>{
					if(item.rec_id==id){
						flag = true;
					}
				})
				return flag;
			},
			handleChangeLogisGoods(val,id,index,index2,goods){
				goods.forEach(item=>{
					if(item.rec_id==val){
						this.logisForm[id][index].goods[index2].quantity = item.quantity;
					}
				})
			},
			handleSubmit(){
				let that = this;
				if(that.$route.params.type=='list' && that.logisType==2 && that.logisCode==''){
					that.$message.error(that.$t('seller.errorMsg.waybillNo'));
					return false;
				}
				let receivingId = that.develifyList.receiving_address?that.develifyList.receiving_address.shipaddr_id:0;
				let shippingId = that.develifyList.shipping_address?that.develifyList.shipping_address.shipaddr_id:0;
				if(!receivingId>0){
					that.$message.error(that.$t('seller.errorMsg.returnGoods'));
					return false;
				}
				if(!shippingId>0){
					that.$message.error(that.$t('seller.errorMsg.shipping'));
					return false;
				}
				let flag = false;
				for(let key in this.logisForm) {
					if(that.$route.params.type=='list') {
						this.logisForm[key].logistic_code = this.logisCode
						this.logisForm[key].remark = this.logisRemark
					}
					if(!this.logisForm[key].logistic_code && parseInt(this.logisType) !== 1) {
						flag = true
					}
				}
				if(flag){
					that.$message.error(that.$t('seller.errorMsg.waybillNo'));
					return false;
				}
				if(that.$route.params.type=='nologis'){
					that.logisType = 1;
				}
				let param = {
					shipping_info: this.logisForm,
					receiving_id: receivingId,
					shipping_id: shippingId,
					type: that.logisType
				}
				onDeliverGoods(param).then(() => {
					that.$message.success(that.$t('seller.successMsg.shipping'))
					that.$router.push({name: 'batchConsign'})
				})
			},
		},
		watch: {
			'addressForm': {
				handler: function(val, oldVal){
					if(val.order_id == oldVal.order_id){
						this.confirmDissbled = false;
					}else {
						this.confirmDissbled = true;
					}
				},
				deep: true,
			}
		}
	}
</script>

<style lang="scss" scoped>
	.consign-order {
		padding: 0;
		::v-deep .el-breadcrumb {
			line-height: 16px;
		}
		.page-head {
			padding: 16px 20px;
			border-bottom: 1px solid #e6e9ed;
		}
		.uu-box {
			height: calc(100vh - 124px);
		}
		.page-container {
			padding: 18px 24px;
			min-width: 980px;
			// width: 100%;
			margin: 0 auto;
			max-width: 1000px;
			// padding-bottom: 20px;
			.page-message {
				// margin-top: 20px;
				.message-notice {
					padding: 12px;
					border-radius: 9px;
					line-height: 18px;
					background-color: $--seller-notice-background;
					.el-icon-warning {
						color: $--seller-primary;
						float: left;
						font-size: 18px;
					}
					.message-notice-main {
						width: 100%;
						color: #666666;
						font-size: 12px;
						padding-left: 24px;
						padding-right: 15px;
						box-sizing: border-box;
						a {
							color: $--seller-primary;
						}
					}
				}
			}
			.card-head {
				display: flex;
				height: 36px;
				align-items: center;
				margin: 28px 0 12px;
				.title {
					color: #111111;
					font-weight: bold;
					font-size: 16px;
					margin-right: 18px;
				}
				.notice {
					color: #888888;
					line-height: 20px;
					.el-icon-warning {
						font-size: 16px;
						margin-right: 4px;
						vertical-align: middle;
					}
				}
				.action {
					flex: 1;
					width: 0;
					display: flex;
					justify-content: space-between;
					::v-deep .el-input__inner {
						padding-right: 64px;
					}
				}
				.w300 {
					width: 300px;
				}
			}
			.card-table {
				width: 100%;
				border-radius: 9px;
				margin-bottom: 12px;
				border: 1px solid #ededed;
				td {
					padding: 12px;
					line-height: 20px;
					border-bottom: 1px solid #ededed;
				}
				tr.bg-grey {
					td {
						background-color: #ebecf0;
					}
				}
				tr:last-child{
					td {
						border-bottom: none;
					}
				}
				.table-cell {
					display: flex;
					font-size: 14px;
					p {
						span {
							color: #999999;
						}
					}
				}
				.table-head {
					align-items: center;
					justify-content: space-between;
					.datetimes {
						display: flex;
						div {
							margin-right: 20px;
						}
					}
				}
				.table-goods {
					.pic {
						width: 60px;
						height: 60px;
						border-radius: 4px;
						object-fit: cover;
						margin-right: 13px;
					}
					.goods-wrap {
						flex: 1;
						width: 0;
						height: 60px;
						display: flex;
						align-items: center;
						.goods-main {
							.name {
								color: $--seller-primary;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							.text {
								margin-top: 5px;
							}
							::v-deep .el-tag {
								color: #333333;
								border-color: $--seller-tag-color-1;
								background-color: $--seller-tag-color-1;
							}
						}
					}
				}
				.table-foot {
					display: block;
					.foot-item {
						display: flex;
						.item-label {
							margin-right: 20px;
							font-weight: bold;
						}
						.item-block {
							flex: 1;
							width: 0;
							display: block;
							.address {
								white-space: nowrap;
								span {
									display: inline-block;
									vertical-align: top;
								}
								i {
									width: 20px;
									height: 20px;
									line-height: 20px;
									text-align: center;
									cursor: pointer;
									margin-left: 10px;
									font-size: 16px;
									display: inline-block;
									vertical-align: top;
								}
								.uu-icon-view {
									background-image: url('~@/assets/images/seller/order-see.png');
									background-size: auto 100%;
									background-repeat: no-repeat;
									background-position: left;
								}
								.uu-icon-view.active {
									background-position: right;
								}
							}
							.item-logis {
								margin-bottom: 12px;
							}
							.item-action {
								display: flex;
								align-items: center;
								margin-bottom: 12px;
								li {
									margin-right: 12px;
									i {
										cursor: pointer;
										font-size: 16px;
									}
								}
								.w30 {
									width: 30px;
								}
								.w80 {
									width: 80px;
								}
								.w100 {
									width: 100px;
								}
								.w260 {
									width: 260px;
								}
							}
							.item-action:last-child {
								margin-bottom: 0;
							}
						}
					}
					.mb6 {
						margin-bottom: 6px;
					}
				}
			}
			.card-info {
				padding: 12px 20px;
				font-size: 14px;
				border-radius: 9px;
				background-color: #f2f4f7;
				border: 1px solid #E5E5E5;
				.item {
					display: flex;
					line-height: 26px;
					margin-bottom: 8px;
					.item-label {
						color: #333333;
						font-weight: bolder;
						margin-right: 16px;
					}
					.item-block {
						flex: 1;
						width: 0;
						i {
							font-size: 16px;
							margin-left: 10px;
							cursor: pointer;
						}
					}
				}
				.item:last-child {
					margin-bottom: 0;
				}
			}
			.card-hr {
				border-bottom: 1px solid #f0f2f5;
			}
			.card-pattern {
				padding-top: 16px;
				.item {
					display: flex;
					align-items: center;
					margin-bottom: 16px;
					.item-block {
						width: 500px;
						flex-shrink: 0;
						margin-right: 20px;
					}
					.item-label {
						flex-shrink: 0;
						color: #888888;
						font-size: 12px;
						.el-icon-question {
							margin-left: 4px;
							font-size: 14px;
						}
					}
				}
				.message-notice {
					padding: 12px;
					border-radius: 9px;
					line-height: 18px;
					background-color: $--seller-notice-background;
					.el-icon-warning {
						color: $--seller-primary;
						float: left;
						font-size: 18px;
					}
					.message-notice-main {
						width: 100%;
						color: #666666;
						font-size: 12px;
						padding-left: 24px;
						padding-right: 15px;
						box-sizing: border-box;
						a {
							color: $--seller-primary;
						}
					}
				}
				.tip {
					color: #666666;
					padding: 30px 0;
					font-size: 14px;
					line-height: 24px;
					text-align: center;
				}
			}
			.dialog-address {
				::v-deep .el-scrollbar {
					height: 500px;
				}
				.dialog-address-main {
					padding: 0 40px;
					overflow: hidden;
					.item {
						display: flex;
						line-height: 32px;
						margin-bottom: 5px;
						font-size: 14px;
						.item-label {
							width: 100px;
							padding-right: 10px;
						}
						.item-block {
							flex: 1;
							width: 0;
							display: block;
						}
						.item-title {
							span {
								margin-right: 20px;
							}
							a {
								color: $--seller-primary;
								text-decoration: underline;
							}
						}
					}
					.address-line {
						padding: 4px 0;
						line-height: 32px;
						.line-label {
							min-width: 68px;
							display: inline-block;
							color: #999999;
						}
					}
					::v-deep .el-form {
						.el-form-item {
							margin-bottom: 10px;
						}
					}
					::v-deep .el-select {
						width: 100%;
					}
				}
			}
			.dialog-addressbase {
				::v-deep .el-dialog {
					.el-dialog__header {
						span {
							font-size: 16px;
						}
						a {
							color: $--seller-primary;
							margin-left: 10px;
						}
					}
					.el-dialog__body {
						padding: 20px;
					}
				}
			}
		}
		.page-foot {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 66px;
			display: flex;
			z-index: 9;
			align-items: center;
			justify-content: center;
			position: absolute;
			background-color: #ffffff;
			box-shadow: 0 -3px 6px 0 rgba(0,0,0,.06);
		}
	}
</style>
