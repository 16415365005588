import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 物流工具
export function freightTplList(data) {
  return request({
    url: '/seller/freight_tpl/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function freightTime(data) {
  return request({
    url: '/seller/freight_tpl/listtime',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createFreightTpl(data) {
  return request({
    url: '/seller/freight_tpl/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateFreightTpl(data) {
  return request({
    url: '/seller/freight_tpl/update',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteFreightTpl(data) {
  return request({
    url: '/seller/freight_tpl/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchAddressList(data) {
  return request({
    url: '/seller/shipaddr/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchAddress(data) {
  return request({
    url: '/seller/shipaddr/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createAddress(data) {
  return request({
    url: '/seller/shipaddr/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateAddress(data) {
  return request({
    url: '/seller/shipaddr/update',
    method: 'post',
    data: param2Obj(data)
  })
}

export function defaultAddress(data) {
  return request({
    url: '/seller/shipaddr/setDefault',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteAddress(data) {
  return request({
    url: '/seller/shipaddr/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getWaybillList(data) {
  return request({
    url: '/seller/ydtemplate/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function expressCompany(data) {
  return request({
    url: '/seller/delivery/list',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchWaybill(data) {
  return request({
    url: '/seller/ydtemplate/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createWaybill(data) {
  return request({
    url: '/seller/ydtemplate/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateWaybill(data) {
  return request({
    url: '/seller/ydtemplate/update',
    method: 'post',
    data: param2Obj(data)
  })
}

export function defaultWaybill(data) {
  return request({
    url: '/seller/ydtemplate/setDefault',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteWaybill(data) {
  return request({
    url: '/seller/ydtemplate/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

