import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 订单
export function getOrderList(data) {
  return request({
    url: '/seller/order/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchOrder(data) {
  return request({
    url: '/seller/order/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateAddress(data) {
  return request({
    url: '/seller/order/updateReceivingAddress',
    method: 'post',
    data: param2Obj(data)
  })
}

export function closeOrder(data) {
  return request({
    url: '/seller/order/cancel',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getCloseReasons(data) {
  return request({
    url: '/seller/common/params',
    method: 'post',
    data: param2Obj(data)
  })
}

export function modifyWaybillNo(data) {
  return request({
    url: '/seller/order/modifyWaybillNo',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deliverGoodsInfo(data) {
  return request({
    url: '/seller/order/deliverGoodsInfo',
    method: 'post',
    data: param2Obj(data)
  })
}

export function onDeliverGoods(data) {
  return request({
    url: '/seller/order/deliverGoods',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchDeliver(data) {
  return request({
    url: '/seller/order/deliveryDetail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchRefundList(data) {
  return request({
    url: '/seller/refund/goods/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchRefundStatics(data) {
  return request({
    url: '/seller/refund/goods/status/statics',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchRefundInfo(data) {
  return request({
    url: '/seller/refund/goods/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchRefundReason(data) {
  return request({
    url: '/seller/common/params',
    method: 'post',
    data: param2Obj(data)
  })
}

export function refundGoods(data) {
  return request({
    url: '/seller/refund/goods/batchReturnGoods',
    method: 'post',
    data: param2Obj(data)
  })
}

export function uploadRefund(data) {
  return request({
    url: '/seller/refund/goods/onlyRefund',
    method: 'post',
    data: param2Obj(data)
  })
}

export function moneyBack(data) {
  return request({
    url: '/seller/refund/goods/batchrefund',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchCommentList(data) {
  return request({
    url: '/seller/order/evaluate/page',
    method: 'post',
    data: param2Obj(data)
  })
}
